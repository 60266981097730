import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const unelueur = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Une lueur" />
    <h3 className='underline-title'>Une lueur</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Le crépuscule de son navire reluit discrétement<br />
        Sur les fronts patibulaires de ses jours reflètent chatouillants<br />
        Quelques rayons mordorés du coucher de soleil d'espoir<br />
        Les rayons agonisants refoulent avec condescendance<br />
        La nuit résolue tirant son voile de jais<br />
        Lutte inégale que mènent les rayons courbaturés<br />
        Face aux vêpres fatales et inexorables<br />
        Étonnant qu'il jaillisse encore<br />
        Certaines étincelles traînardes d'inégal duel <br />
        Ô lueur fugitive à effet d'étoile filante!<br />
        Il te préfère la lanterne d'âge néolithique<br />
        Épandant faiblement ses rayons vespéraux<br />
        Rayons temporisant à s'éteindre<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default unelueur
